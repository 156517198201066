@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.optionRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: size("gutter--small") 0;

  @media (max-width: (breakpoint("small") - 1px)) {
    align-items: stretch;
  }
}

.input {
  margin-top: size("gutter--medium");
}

.optionLabel {
  margin: size("gutter--medium") 0 size("gutter--mini") !important;
}