@import "ui-components/_variables.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  background-color: var(--light);
  color: var(--text);
  padding: size(gutter--large) 0 size(gutter--section);
}

.promotion {
  display: flex;
  color: var(--text);
  border: 1px solid var(--border);

  .left,
  .right {
    width: 50%;
  }

  .left {
    margin-bottom: 0;
    margin-right: size("gutter--large");
  }

  .right {
    margin-left: size("gutter--large");
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: size("gutter--large");
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex-direction: column;

    .left,
    .right {
      width: 100%;
      margin: initial;
    }

    .left {
      &:not(.empty) {
        margin-bottom: size("gutter");
      }
    }
  }
}

.howToEarnImage {
  height: 100%;
  object-fit: cover;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title,
  .description {
    max-width: 650px;
  }
}

.cta {
  background-color: var(--light);
  border-radius: var(--borderRadius);
  padding: size("gutter--small") size("gutter--medium");


  &Large {
    font-size: size("font--large");
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: size("title-margin");

  h2 {
    margin-bottom: 0;
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    flex-direction: column;
  }
}

.featuredProducts,
.earnOnline {
  display: flex;
  justify-content: center;
}

@media (max-width: (breakpoint("tiny") - 1px)) {

  .cta,
  .ctaLarge {
    font-size: size("font") !important;
  }
}

.wrapper {
  margin-top: size("gutter--section");

  @media (max-width: (breakpoint("medium") - 1px)) {
    margin-top: size("gutter--huge");
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-top: size("gutter--huge") 0;
  }

  &>*:not(:first-child) {
    margin-top: size("gutter--section");
  }
}

img {
  border-radius: calc(size("gutter--small") / 2);
}

.currenInfoCardsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: size("gutter");

  @media (min-width: breakpoint("medium")) {
      grid-template-columns: 1fr 1fr 1fr;
  }
}

.currenInfoCardImage {
  display: flex;
  justify-content: center;
  margin: size("grid-gutter") 0;
}

.currenInfoCardsSectionCardWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0;

  @media (max-width: (breakpoint("medium") - 1px)) {
      flex: 0 0 100%;
  }
}

.currentInfoCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light);
  color: var(--text);
  box-shadow: 5px 5px 15px 1px rgba(0,0,0,0.1);
  flex-grow: 1;
  transition: transition("duration--atoms") transition("ease-in");
  padding: size("gutter--large");

  @media (max-width: (breakpoint("small") - 1px)) {
    padding: size("gutter");
  }

  img {
    width: 100%;
    margin-bottom: size("grid-gutter");
  }

  h3 {
    margin-bottom: size("grid-gutter");
  }

  p {
    margin-top: 0;
  }
  &Content {
      padding: size("gutter");
  }
}

.currentInfoDescription {
  font-size: size("font--small");
  max-width: 600px;
  display: flex;
  flex-direction: column;

  a {
      display: table;
      background-color: var(--primary);
      color: var(--light);
      padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
      margin-top: size("gutter");
      border-radius: var(--borderRadius);
  }
}
