@import "ui-components/_variables.scss";


.HeroLeaderboardTable {

  .tableItem {
    padding: size("gutter--mini") size("gutter--large");
    display: flex;
    justify-content: flex-start;

    &:not(:first-of-type) {
      border-top: 1px solid var(--border);
    }

    p {
        margin: 0;
    }

    .itemIndex {
        margin-right: size("gutter--small");
        font-weight: bold;
    }

    .itemPoints {
        margin-left: auto;
    }

  }

  .currentUser {
    font-weight: bold !important;
    color: var(--primary)
    // p {
    //   font-weight: bold !important;
    // }
  }
}