@import "ui-components/_variables.scss";

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
}

.hero {
  height: 600px;
  width: 100%;
  max-width: breakpoint("large");
  margin: auto;
  overflow: hidden;
  border-radius: var(--borderRadius);

  img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
  }

  .image {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .content {
    display: block;
    position: absolute;
    transform: translate(10%, -14%);
    background-color: var(--background);
    border-radius: var(--borderRadius);
    color: var(--invertedText);
    max-width: 35rem;
    bottom: 0;
    padding: 3rem;

    & > h1 {
      margin-bottom: 1rem;
      font-size: 2.5rem;
    }

    & > p {
      margin: 0;
      font-size: size("font--medium");
    }

    @media (max-width: (breakpoint("medium") - 1px)) {
      & > h1 {
        font-size: size("font--huge");
      }
    }

    @media (max-width: breakpoint("small")) {
      max-width: initial;
      transform: initial;
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      & > h1 {
        font-size: size("font--large");
      }
    }
  }
  .heroIcon {
    max-width: 200px;
  }
}
