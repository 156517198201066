@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$max-image-height: 240px;

.block {
  display: flex;
  background-color: var(--light);
  color: var(--text);
  width: 100%;
  border-radius: var(--borderRadius);
  border: 1px solid var(--border);
  overflow: hidden;

  h2 {
    font-size: size("font--huge");
  }

  &:first-child {
    .image {
      order: 2;
    }
  }

  &:first-child:last-child {
    max-height: none;
  }

  @media (max-width: breakpoint("medium")) {
    flex-direction: column;
    max-height: unset;

    &:first-child {
      .image {
        order: unset;
      }
    }
  }
}

.wrapper {
  flex: 1 0 auto;
  padding: size("gutter--larger");
  width: 33%;

  @media (max-width: breakpoint("medium")) {
    width: 100%;
  }
}

.imageBlock {
  @media (max-width: breakpoint("medium")) {
    flex-direction: column-reverse;
    height: fit-content;

    &:first-child {
      .image {
        order: unset;
      }
    }
  }

  .image {
    flex: 0 0 50%;
  }

  .wrapper {
    flex: 0 0 50%;
    padding: size("gutter--medium");

    @media (max-width: breakpoint("medium")) {
      width: 100%;
    }
  }

  .imageWrapper {
    overflow: hidden;

    @media (max-width: breakpoint("medium")) {
      width: 100%;
      max-height: $max-image-height;
    }

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }
}