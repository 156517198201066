@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$item-width: 110px;
$image-max-width: 800px; // The product image size on the server is 800x80
$gallery-stagger-delay: 50ms;

:export {
  galleryStaggerDelay: $gallery-stagger-delay;
  breakpoint: breakpoint("small");
}

@keyframes productViewMediaPopIn {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.block {
  &__left, &__right {
    display: flex;
  }

  &__left {
    flex-direction: row-reverse;
  }

  img {
    width: 100%;
  }
}

.image {
  border: 1px solid var(--border);
  border-radius: var(--borderRadius);
  overflow: hidden;

  .block__left &, .block__right & {
    flex: 1 0 auto;
  }
}

.gallery {
  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: center;
    flex-direction: column;

    .block__bottom &, .block__top & {
      margin-left: -#{math.div(size("grid-gutter"), 2)};
      margin-right: -#{math.div(size("grid-gutter"), 2)};

      flex-wrap: wrap;
      flex-direction: row;
    }

    .block__left &, .block__right & {
      margin-top: -#{math.div(size("grid-gutter"), 2)};
      margin-bottom: -#{math.div(size("grid-gutter"), 2)};
      width: calc(#{$item-width} + #{size("grid-gutter")});
      flex: 0 0 auto;
    }

    .block__top & {
      margin-bottom: math.div(size("grid-gutter"), 2);
    }

    .block__bottom & {
      margin-top: math.div(size("grid-gutter"), 2);
    }

    .block__left & {
      margin-right: math.div(size("grid-gutter"), 2);
    }

    .block__right & {
      margin-left: math.div(size("grid-gutter"), 2);
    }
  }

  &Item {
    display: block;
    border-radius: size("border-radius");
    overflow: hidden;

    width: 100%;
    padding: math.div(size("grid-gutter"), 2) !important;

    opacity: 0;
    transform: scale(0.4);
    animation: productViewMediaPopIn transition("duration--templates") transition("ease-ease") forwards;

    img {
      width: 100%;
    }
  }
}

.block__top, .block__bottom {
  .gallery__perRow_1 .galleryItem { flex: 0 0 100%; }
  .gallery__perRow_2 .galleryItem { flex: 0 0 50%; }
  .gallery__perRow_3 .galleryItem { flex: 0 0 33.333%; }
  .gallery__perRow_4 .galleryItem { flex: 0 0 25%; }
  .gallery__perRow_5 .galleryItem { flex: 0 0 20%; }
}

.carousel {
  user-select: none;
  @media (max-width: breakpoint("tiny")) {
    overflow: hidden;
  }

  &Picture {
    position: absolute;
    width: 100%;
  }

  &Controls {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 65px;

    @media (min-width: breakpoint("small")) {
      display: none;
    }
  }
}

.lightboxImageWrapper {
  flex: 1 0 auto;
  display: flex;
  width: 100%;
  max-width: $image-max-width;
}

.lightbox {
  display: flex;
  flex-direction: column;
  align-items: center;

  &Image {
    flex: 1 0 auto;
    width: 100%;
    max-width: $image-max-width;
  }

  &Gallery {
    display: flex;
    justify-content: center;

    position: fixed;
    bottom: 0;
    z-index: z-index("popup");

    &Item {
      display: block;
      flex: 0 0 auto;
      width: $item-width;
      height: $item-width;

      margin: size("grid-gutter") math.div(size("grid-gutter"), 2);
      padding: 0.2em;
      border: 1px solid transparent;

      &.active {
        border-color: color("primary");
      }
    }

    img {
      opacity: 1;
      transform: scale(1);
      animation: none;
      width: 100%;
    }
  }

  &Close {
    position: fixed;

    z-index: 9999;
    background: #fff;
    border-radius: 50%;
    padding: 1rem;

    svg {
      width: auto;
      height: auto;
    }
  }

  .galleryItem {
    width: $item-width;
  }
}

.imagePlaceholder {
  background: color("body--off");
}
