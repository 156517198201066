@import "ui-components/_variables.scss";

.block {
  min-width: 1px;
  min-height: 1px;

  svg {
    overflow: visible;
  }

  img {
    max-height: 100%;
  }
}

.name {
  font-family: font("header");
  font-size: size("font--medium");
}

.svg {
  color: var(--primaryAlt);
}
