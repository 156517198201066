@import "../../node_modules/@crossroads/ui-components/src/ui-components/Button.scss";

$border-radius: var(--borderRadiusButton);

.button[class] {
  border: none;
  border-radius: $border-radius !important;

  & > span {
    display: block;
    margin-top: 1px;
    margin-bottom: -1px;
  }

  &.primary {
    color: var(--light);
    background: var(--primary);
    background-position: center;
    letter-spacing: font("uc-letter-spacing");
    font-size: size("font--medium-small");
    transition: background 0.1s;

    &:enabled {
      &:hover {
        background: var(--primary-l1);
      }

      html[data-whatintent="keyboard"] &:focus {
        background-color: var(--primary-l1);
      }

      &:active:hover {
        color: var(--border);
        background-color: var(--primary-d1);
        background-size: 100%;
        transition: background 0s;
      }
    }

    &:disabled:not(.loading) {
      background: color("disabled");
      color: rgba(var(--text), 0.35);
    }
  }

  &.secondary {
    @include commonButton(
      $color: var(--primary),
      $background: inherit,
      $borderColor: var(--primary),
      $br: $border-radius
    );
    font-size: size("font--medium");
    border-width: 2px;

    &:enabled {
      &:hover {
        border-color: var(--primary-l1);
        color: var(--primary-l1);
      }

      &:active:hover {
        border-color: var(--primary-l2);
        color: var(--primary-l2);
      }

      html[data-whatintent="keyboard"] &:focus {
        border-color: var(--primary-l1);
        color: var(--primary-l1);
      }
    }
  }

  &.blank {
    @include commonButton(
      $color: var(--primary),
      $br: $border-radius
    );
    border: 2px solid var(--light);
    transition: border-color 0.1s;
    font-size: size("font--medium-small");

    &:enabled {
      &:hover,
      &:active:hover {
        background: var(--light);
        border-color: var(--primary-d1);
        color: var(--primary-d1);
      }

      &:hover {
        border-color: var(--primary);
        background: var(--light);
        color: var(--primary);
      }

      html[data-whatintent="keyboard"] &:focus {
        border-color: var(--primary);
        background: var(--light);
      }
    }
  }

  &.ghost {
    @include commonButton(
      $color: var(--primary),
      $background: inherit,
      $borderColor: var(--primary),
      $br: $border-radius
    );
    font-size: size("font--medium");
    border-width: 2px;

    &:enabled {
      &:hover {
        border-color: var(--primary-l1);
        color: var(--primary-l1);
      }

      &:active:hover {
        border-color: var(--primary-l2);
        color: var(--primary-l2);
      }

      html[data-whatintent="keyboard"] &:focus {
        border-color: var(--primary-l1);
        color: var(--primary-l1);
      }
    }
  }

  .hasVariant.size {
    &__large {
      padding: size("gutter--mini") * 1.17 size("gutter--large") size("gutter--mini") * 1.1;
    }

    &__medium {
      padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
    }

    &__small {
      padding: size("gutter--mini") * 0.65 size("gutter--small") size("gutter--mini") * 0.5;
      font-size: size("font");
    }
  }

  &.hasVariant {
    &.link {
      //@extend %commonTransition;
      padding: 0;
      color: var(--primary);
      text-decoration: none;
      touch-action: manipulation;
      -webkit-tap-highlight-color: rgba(#fff, 0);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        outline: 0;
      }

      &:active:hover {
        opacity: 0.5;
      }

      &[disabled] {
        cursor: not-allowed;
      }
    }
  }
}

