@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$paddingTopLarge: 250px;
$paddingTopSmall: 150px;
$maxWidthLoginForm: 425px;

:export {
  small: strip-unit(breakpoint(small));
}

.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--light);
}

.content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: center;
  align-content: center;

  &__text {
    color: var(--invertedText);
    max-width: 30rem;
    margin: size(gutter);

    h1 {
      color: var(--invertedText);
    }

    @media (max-width: (breakpoint("small") - 1px)) {
      margin: size(gutter) auto;
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    min-width: 0;
    width: 100%;
    flex-direction: column-reverse;
    padding: size(gutter);
  }
}

.title {
  font-size: size("font--huge");
}

.top {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  max-width: $maxWidthLoginForm;
  margin: size(gutter);

  @media (max-width: (breakpoint("small") - 1px)) {
    min-width: 0;
    width: 100%;
    margin: size(gutter) auto;
  }
}


