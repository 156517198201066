@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$inset: 1rem;
$background: var(--light);
$disabledBackground : color("disabled");
$transition: border-color transition("duration--molecules"), color transition("duration--molecules");

.block {
  display: block;
}

.label {
  display: block;
  margin: math.div(size("gutter--small"), 1.5) 0;

  .error & {
    color: color("negative") !important;
  }
}

.input {
  @extend %resetInput;
  width: 100%;
  line-height: font("line-height");
  font-size: max(16px * strip-unit(math.div(font("size"), 100)), 16px);
  padding: $inset;
  letter-spacing: inherit;
  background-color: $background;
  transition: $transition;
  font-weight: font("weight--bold");
  color: color("text--dark");
  border: 1px solid color("border");
  border-radius: size("border-radius");

  .error & {
    border-color: color("negative");

    &:enabled:focus {
      border-color: color("negative", -40%);
    }
  }

  &:enabled {
    &:focus {
      border-color: color("border", -40%);
    }
  }
}

.errors {
  margin-top: math.div(size("gutter--small"), 1.5);
  color: color("negative");
  font-size: size("font--small");
}
