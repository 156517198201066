@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$padding: size("gutter--large");
$padding--small: 1.2rem;

.block {
  height: size("sub-header-height");
  background: var(--secondary);
  color: var(--text);
  z-index: z-index("overlay");
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * + * {
    margin-left: size("gutter--small");
  }
}

.submenu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: breakpoint("large");
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding--small;
  padding-right: $padding--small;

  @media (min-width: breakpoint("small")) {
    padding-left: $padding;
    padding-right: $padding;
  }

  .links {
    display: flex;
  }
}

.submenuEarn {
  justify-content: flex-end;
  align-items: center;
}

.link {
  color: var(--text);

  &:hover {
    text-decoration: none;
  }
}

.item {
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;

  &.active .chevron {
    transform: rotate(90deg) scaleX(-1);
  }

  &:not(.item__cart) {
    color: var(--text);
  }

  &:hover:not(.item__search):not(.item__customerBadge) {
    text-decoration: underline;
  }

  &.active:not(.item__search):not(.item__customerBadge) {
    text-decoration: underline;
  }

  &__search {
    svg {
      margin-right: size("gutter--mini");
      width: 22px;
      height: 22px;
    }

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &:hover > div,
    &:active > div {
      text-decoration: underline;
    }
  }

  &__customerBadge {
    &:hover {
      text-decoration: none;

      p:first-child {
        text-decoration: underline;
      }
    }

    &.active {
      p:first-child {
        text-decoration: underline;
      }
    }
  }

  & + & {
    margin-left: size("gutter--large");
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  &__categories {
    color: var(--text);

    .chevron {
      transition: all transition("duration--organisms") transition("ease-ease");
      top: 0;
      transform: rotate(90deg);
      margin-left: size("gutter--mini");
    }
  }

  @media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
    font-size: 14px;
  }
}

.categories,
.cart {
  background: #fff;
  border-radius: var(--borderRadius);
  margin-top: size("gutter--mini");
  padding: size("gutter--small");
  text-transform: none;
  font-weight: normal;
}

.categories {
  position: absolute;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.1);
  top: size("sub-header-height");
  min-width: size("categories-width");
  right: auto !important;
}

.questionnaire {
  width: 100%;
  display: flex;
  max-width: breakpoint("large");
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding--small;
  padding-right: $padding--small;

  @media (min-width: breakpoint("small")) {
    padding-left: $padding;
    padding-right: $padding;
  }

  .link {
    color: var(--textMuted);

    &.active {
      color: var(--text);
      text-decoration: underline;
    }

    &:hover {
      color: var(--text);
    }
  }

  > * + * {
    margin-left: size("gutter--small");
  }
}

.cart {
  position: absolute;
  top: size("sub-header-height") !important;
  left: auto !important;
  right: size("gutter") !important;
}

.search {
  transition:
    width transition("duration--organisms") transition("ease-out"),
    opacity transition("duration--organisms") transition("ease-out");
}

.categories,
.cart {
  background: #fff;
  border: 1px solid var(--border);
  left: 0;
  right: 0;
  z-index: z-index("system") + 1;
  animation: slideDown transition("duration--organisms") transition("ease-out") forwards;
}

.close {
  color: var(--primary);
  font-size: size("font--medium-small");
  margin: size("gutter") size("gutter--large") !important;

  &:hover {
    text-decoration: underline;
  }
}
