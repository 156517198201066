@use "sass:math";

@import "ui-components/_variables.scss";

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
}

.hero {
  min-height: 600px;
  width: 100%;
  max-width: breakpoint("large");
  margin: auto;
  overflow: hidden;
  border-radius: var(--borderRadius);
  padding: size("gutter--large");
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: breakpoint("small")) {
    flex-wrap: wrap;
    border-radius: 0;
    height: auto;
    padding-bottom: 0;
  }

  h2 {
    margin-bottom: size("gutter--medium");
    font-size: size("font--title");
  }

  img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
  }

  .image {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @media (max-width: breakpoint("small")) {
      position: static;
    }
  }
}

.fullWidth {
  .hero {
    max-width: none;
    min-height: 70vh;
    padding: 0;
    margin-top: size("gutter--large") * -1;
  }
  .widgetWrapper {
    max-width: 1270px;
    padding: size("gutter--large");
    margin: 0 auto;
  }
}

.loadingWrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.widgetWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: breakpoint("small")) {
    flex-wrap: wrap;
  }
}

.widget {
  background: #fff;
  padding: size("gutter--medium") 0;
  width: calc(50% - #{size("gutter--small")});
  color: var(--text);
  border-radius: var(--borderRadius);

  @media (max-width: breakpoint("small")) {
    width: 100%;
    margin-bottom: size("gutter--medium");
    &:nth-child(2) {
      border-radius: 0 0 var(--borderRadius) var(--borderRadius);
    }
  }

  h2 {
    padding: 0 size("gutter--large");
    line-height: font("line-height--paragraph");
    @media (max-width: breakpoint("small")) {
      font-size: size("font--large");
    }
  }

  .widgetRow {
    width: 100%;
    border-top: 1px solid var(--border);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-left: size("gutter--large");
      width: 20px;
      height: 20px;
      fill: var(--primary);
      color: var(--primary);
      flex-shrink: 0;
      @media (max-width: breakpoint("small")) {
        margin-left: size("gutter--small");
      }
    }

    p {
      padding: math.div(size("gutter--small"), 1.5) size("gutter--large") math.div(size("gutter--small"), 1.5) size("gutter--small");
      margin: 0;
      color: var(--primary);
      @media (max-width: breakpoint("small")) {
        padding: math.div(size("gutter--small"), 1.5) size("gutter--small");
      }
    }

    p:last-child {
      margin-left: auto;
    }
  }

  .cta {
    display: flex;
    border-top: 1px solid var(--border);
    padding: 0 size("gutter--large");
    padding-top: size("gutter--large");
    justify-content: space-between;

    @media (max-width: breakpoint("small")) {
      flex-wrap: wrap;
    }

    a:last-child button {
      margin-bottom: 0;
    }

    button {
      padding: size("gutter--small") size("gutter--medium");
      @media (max-width: breakpoint("small")) {
        margin-bottom: size("gutter--medium");
      }
    }
  }

  .description {
    max-width: 100%;
    padding: size("gutter--small") size("gutter--large");
    color: var(--text);
    border-top: none;

    p {
      margin-left: 0 !important;
    }
  }
}

.missingData {
  display: flex !important;
  justify-content: center !important;
  padding: size("gutter--larger") 0;
}

.report {

  div {
    border-top: none;
    padding-top: 0;

    p {
      color: var(--text);
      padding: 0;
    }
  }

  .description {
    padding: 0 size("gutter--large");
  }

  .cta {
    border-top: none;
    padding-top: size("gutter--large");
  }

}

.motivationDescription {
  border-top: 1px solid var(--border);
  margin-left: 0;
  p {
    color: var(--text);
    padding: size("gutter--small") size("gutter--large");

  }
}

.widgetHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 size("gutter--large") size("gutter") size("gutter--large");

  p {
    margin: 0 size("gutter--mini") 0 0;
  }

  h2 {
    padding: 0;
    margin-bottom: 0;
  }
}

.districtDropdownContainer {

  .districtDropdown {
    div {
      padding: size("gutter--small");
    }
  }
}

.widgetControls {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  
  padding: 0 size("gutter--large") size("gutter") size("gutter--large");
  gap: size("gutter");
}

.widgetCtas {
  display: flex;
  align-items: baseline;
  gap: size("grid-gutter");

  .btn {
    background: color("text");
    color: #efefef;
    padding: size("grid-gutter");
  }
}


.activeLeaderboard {
  background: color("text--muted") !important;
  pointer-events: none;
}
