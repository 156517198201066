@import "ui-components/_variables.scss";

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.height {
  height: size("header-height");
  margin-bottom: size("header-margin");

  @media (min-width: breakpoint("small")) {
    .fullMenu & {
      height: size("header-height") + size("sub-header-height");
    }
  }

  &.noMargin {
    margin: 0;
  }
}

@media (max-width: breakpoint("small")) {
  .hasMobileSubNav .height {
    height: size("header-height") + size("sub-header-height") !important;
  }
}

.notices {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: z-index("popup") + 1;
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  > div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.children {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.bannerWarning {
  background-color: #fef7f8;
  text-align: center;
  margin-top: size("header-margin") * -1 !important;

  @media (max-width: 1240px) {
    margin-bottom: size("gutter--large");
  }

  .title[class] {
    margin: 0;
  }
}

.footerSpacer {
  height: size("gutter--section");
  margin-top: size("gutter");
  background-color: var(--light);
}
